.messageIcon {
  fill:  var(--color-blue-base);
}

.messageFundraiserButton {
  background: initial;
  border: none;
  text-decoration: none;
  &:hover { text-decoration: none; }
}
